<template>
  <b-modal
    id="EmployeeWorkContractInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/contracts.svg" class="icon-lg" />
        {{ $t("employeeWorkContracts.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeNameCurrent"
        :title="$t('employeeName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeWorkContractCode"
        :title="$t('employeeWorkContracts.code')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeWorkContractNameAr"
        :title="$t('employeeWorkContracts.nameAr')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeWorkContractNameEn"
        :title="$t('employeeWorkContracts.nameEn')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeWorkContractNameUnd"
        :title="$t('employeeWorkContracts.nameUnd')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.contractFormData.contractFormNameAr"
        :title="$t('ContractForms.nameAr')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.contractFormData.contractFormNameEn"
        :title="$t('ContractForms.nameEn')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.contractFormData.contractFormNameUnd"
        :title="$t('ContractForms.nameUnd')"
        :imgName="'contracts.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          formateDateTimeLang(
            employeeWorkContractData.employeeWorkContractStartDate,
            employeeWorkContractData.employeeWorkContractStartTime
          )
        "
        :title="$t('employeeWorkContracts.dateTimeStart')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          formateDateTimeLang(
            employeeWorkContractData.employeeWorkContractEndDate,
            employeeWorkContractData.employeeWorkContractEndTime
          )
        "
        :title="$t('employeeWorkContracts.dateTimeEnd')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeWorkContractDuration"
        :title="$t('employeeWorkContracts.contractDuration')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeWorkContractSalary"
        :title="$t('employeeWorkContracts.contractSalary')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="employeeWorkContractData.employeeMakeActionNameCurrent"
        :title="$t('userMakeAction')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          formateDateTimeLang(
            employeeWorkContractData.employeeWorkContractActionDate,
            employeeWorkContractData.employeeWorkContractActionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "EmployeeWorkContractInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeWorkContractData"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
};
</script>

<style scoped lang="scss"></style>
